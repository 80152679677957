// 固定二维码配置投保模块相关接口
import { get, getUrl, post, postUrl } from './http';

export function http_getqrCodeInfo(id) {
	return getUrl('/insurance/api/insUser/sign/findInsUserSignVoById', id);
}

export function http_getUserSetting() {
	return get('/insurance/api/insUserConfig/getInsUserConfig');
}

// 操作缓存页面
// 因为一些信息填写页面有缓存，在该页面返回产品详情页面后，缓存还在，切换计划再进入信息填写页面后，会导致产品计划信息错误
// 该组件一般用在产品信息页面
// 在使用该组件的页面中，data中要定义一个变量 setKeepAlivePageName  值是配套的投保信息填写页面
export default {
	beforeRouteEnter(to, from, next) {
		// 清空缓存页面
		next(vm => {
			vm.$store.commit('delete_keepAlivePage', vm.setKeepAlivePageName);
		});
	},
	beforeRouteLeave(to, from, next) {
		// 把页面加入缓存列表中
		this.$store.commit('add_keepAlivePage', this.setKeepAlivePageName);
		next();
	},
};

<style lang="scss" scoped>
.body {
	font-size: 0.28rem;
}
header {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	img {
		width: 150%;
	}
}
.p1 {
	padding: 0.1rem;
}
</style>

<template>
	<div class="body">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div v-else>
			<!-- <top-nav :showBack="false">“耀守护”教培机构责任险</top-nav> -->
			<top-nav :showBack="false">“耀守护”培训机构责任险</top-nav>
			<!-- banner -->
			<header>
				<img src="@/assets/image/liability/jpjg.png" alt="banner" />
			</header>

			<!-- 计划 -->
			<product-plan :plans="planList" :proInfo="product" :insureInfo="insureInfo" :information="information" :price="money" :ageFormatter="productAgeFormatter" :nameFormatter="productNameFormatter" @planChange="planChange" priceSuffix="起" />

			<!-- 产品解读 -->
			<product-narrate :information="information" :questionList="questionList" />

			<!-- 购买按钮 -->
			<price-buy-btn :price="money" @buy="buy" :isword="true"></price-buy-btn>

			<!-- 确定进入投保流程 -->
			<enter-sure v-model="enterWarning" :name="insurerFullName" :fileUrl="notificationLink" @confirm="agreeEnter" :proId="[p1, p2]" @cancel="stopKhs" @clickOverlay="stopKhs"></enter-sure>
		</div>
	</div>
</template>

<script>
import user from '@/components/user.js';
import { productDetail, getInsuerInfo } from '@/request/api.js';
import { http_getPalns } from '@/request/sundry.js';
import productPlan from '@/components/productPlan.vue';
import productNarrate from '@/components/productNarrate.vue';
import enterSure from '@/components/enterSure.vue';
import setKeepAlive from '@/components/setKeepAlive';
export default {
	name: 'jiaoPeiJiGou_index', // 教培机构责任险产品详情页
	mixins: [user, setKeepAlive],
	components: {
		productPlan,
		productNarrate,
		enterSure,
	},
	data() {
		return {
			weChatWarningShow: false,

			queryIsOk: false, // 检查参数是否正常，如果参数异常，不执行页面逻辑
			userId: '',
			p1: '', // 商品第一部分id
			p2: '', // 商品第二部分id

			planList: [],
			product: {},
			insureInfo: {},
			information: {},
			questionList: [],

			enterWarning: false,
			notificationLink: '', // 告知书文档链接
			insurerFullName: '', // 保险公司名称

			priceList: [], // 公众险所有费率
			priceList2: [], // 雇主险所有费率
			price1: null, // 公众险所选费率信息
			price2: null, // 雇主险所选费率信息

			plan: null, // 选中的计划
			planId: '', // 第一部分的计划id
			planId2: '', // 第二部分对应的计划id
			planNameIdDict2: {}, // 第二部分的计划名称与计划id的映射关系

			thirdPartyCallbackParam: '', // 第三方自定义回调参数

			setKeepAlivePageName: 'jiaoPeiJiGou_insure',
		};
	},
	computed: {
		// 保费
		money() {
			let m1 = 0,
				m2 = 0;
			let m1_priceList = this.priceList.filter(item => item.comboId == this.planId && item.eleView1 === '常规运动版' && item.eleUse1 === '不含猝死');
			let m2_priceList = this.priceList2.filter(item => item.comboId == this.planId2 && item.eleView1 === '常规运动版');
			if (m1_priceList.length) {
				m1 = Number(m1_priceList[0].price);
				this.price1 = m1_priceList[0];
			}

			if (m2_priceList.length) {
				m2 = Number(m2_priceList[0].price);
				this.price2 = m2_priceList[0];
			}

			return (m1 + m2).toFixed('2');
		},
	},
	created() {
		this.init();
	},
	methods: {
		stopKhs() {
			khs.stop();
		},
		// 初始化
		init() {
			if (!this.$base.isDev() || this.$base.getEnv()) {
				this.$base.saveUrl();
				this.getQuery();
				if (this.queryIsOk) {
					this.mixin_login().then(userInfo => {
						this.userId = userInfo.id;
						this.getProduct();
						this.getInsureInfo();
						this.getProductPrice();
					});
				}
			} else {
				this.weChatWarningShow = true;
			}
		},

		// 商品名称格式化
		productNameFormatter(nameStr) {
			return nameStr.split('-')[0];
		},

		productAgeFormatter(age) {
			return '不限';
		},

		// 获取地址中的参数
		getQuery() {
			let query = this.$route.query;
			if (query && Object.hasOwnProperty.call(query, 'product')) {
				let ids = query.product.split(',');
				if (ids.length === 2) {
					this.p1 = ids[0];
					this.p2 = ids[1];
					this.queryIsOk = true;
				}
				this.thirdPartyCallbackParam = query.thirdPartyCallbackParam;
			}
		},

		// 获取保险商品信息
		getInsureInfo() {
			getInsuerInfo(this.p1).then(res => {
				// 取告知书文档链接
				if (res.bookList) {
					let bookList = res.bookList.filter(item => item.docType === '03');
					if (bookList.length) {
						this.notificationLink = res.productFileServer + bookList[0].docLink;
					}
				}

				// 取保险公司名称
				this.insurerFullName = res.insurer.insurerFullName;
			});
		},

		// 获取产品费率
		getProductPrice() {
			// 公众险费率
			http_getPalns(this.p1).then(res => {
				res.forEach(item => {
					this.priceList.push(...item.productPriceList);
				});
			});

			// 雇主险费率
			http_getPalns(this.p2).then(res => {
				res.forEach(item => {
					this.priceList2.push(...item.productPriceList);
				});
			});
		},

		// 获取商品详情
		getProduct() {
			productDetail(this.p2).then(res => {
				let planNameIdDict = {};
				res.combos.forEach(item => {
					planNameIdDict[item.comboName] = item.comboId;
				});
				this.planNameIdDict2 = planNameIdDict;

				productDetail(this.p1).then(res => {
					this.planList = res.combos;
					this.product = res.product;
					this.insureInfo = res.insurer;
					this.information = res.information;
					this.questionList = res.questionList;
					if (this.planList.length) {
						this.planChange(this.planList[0].comboId);
					}
				});
			});
		},

		// 计划改变
		planChange(plan) {
			this.planId = plan.comboId;
			this.plan = plan;
			this.planId2 = this.planNameIdDict2[plan.comboName];
		},

		// 购买
		buy() {
			khs.start(this.userId);
			this.enterWarning = true;
		},

		// 同意进入投保流程
		agreeEnter() {
			this.$router.push({
				// path: 'insure',
				name: 'jiaoPeiJiGou_insure',
				query: {
					p1: this.p1,
					p2: this.p2,
					planId: this.plan.comboId,
					planName: this.plan.comboName,
					uid: this.userId,
					thirdPartyCallbackParam: this.thirdPartyCallbackParam,
				},
			});
		},
	},
};
</script>

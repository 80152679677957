// 用户登录、获取用户配置参数
import { loginNoPwd } from '@/request/api';
import { http_getqrCodeInfo, http_getUserSetting } from '@/request/fixedCodeInsure';

export default {
	data() {
		return {
			mixin_userDesc: '',
			mixin_qrCodeId: '',

			mixin_qrCodeInfo: {}, // 立牌信息
			mixin_userSetting: {}, // 用户配置信息
		};
	},
	created() {
		this.mixin_getQuery();
	},
	methods: {
		// 获取地址中的参数
		mixin_getQuery() {
			let query = this.$route.query;
			console.log(query);
			if (query) {
				this.mixin_userDesc = query.user;
				this.mixin_qrCodeId = query.qrcode;
			}
		},

		// 登录
		mixin_login() {
			return new Promise(resolve => {
				loginNoPwd(this.mixin_userDesc).then(res => {
					resolve(res.userInfo);
				});
			});
		},

		/**
		 * 获取立牌配置信息
		 * @param {String} dataKey data中存储立牌信息的变量
		 * @returns Promise
		 */
		mixin_getQrCodeInfo(dataKey = 'mixin_qrCodeInfo') {
			return new Promise(resolve => {
				http_getqrCodeInfo(this.mixin_qrCodeId).then(res => {
					this.$set(this, dataKey, res);
					this.$store.commit('set_productId', res.proId);
					resolve(res);
				});
			});
		},

		// 获取用户配置信息
		mixin_getUserSetting() {
			return new Promise(resolve => {
				http_getUserSetting().then(res => {
					this.$set(this, 'mixin_userSetting', res);
					resolve(res);
				});
			});
		},
	},
};
